<template>
  <div class="container mx-auto" v-if="product">
    <div class="flex flex-col lg:flex-row">
      <div class="flex-1 p-4 lg:p-12 lg:w-6/12">
        <h1 class="text-3xl !leading-tight lg:hidden line-clamp-3">
          {{ product.title }}
        </h1>
        <div class="md:flex-1 px-4">
          <div>
            <div class="rounded-lg my-4">
              <img :src="slider.img"
                      id="image-preview"
                      class="h-64 md:h-1/2 w-full object-contain rounded-lg mb-4 flex items-center justify-center overflow-hidden"
              />
            </div>

            <div class="flex -mx-2 mb-4 gap-3 justify-center">
              <div :class="['ring-indigo-300 ring-inset w-24 md:h-24 p-2 overflow-hidden slide-placeholder cursor-pointer', {'ring-2': slider.currentIndex === i}]"
                   v-for="(img, i) in product.images"
                   :key="'img_'+i"
                   @click="changeImage(i, img)">
                <img :src="img"
                     class="object-contain focus:outline-none w-full rounded-lg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-1 p-4 lg:p-12">
        <h1 class="font-bold text-3xl leading-tight hidden lg:flex line-clamp-3">
          {{ product.title }}
        </h1>

        <div class="flex items-center lg:mt-8 flex-col lg:flex-row">
          <h3 class="text-3xl lg:text-6xl text-yellow-500 italic">${{ product.price.current_price }}</h3>
          <div class="flex-1"></div>
          <a target="_blank"
             :href="amazonLink">
            <img src="/buyonamazon.png" class="w-60 mt-1 lg:mt-0" alt="" />
          </a>
        </div>
        <h3 class="text-xl lg:text-3xl mt-8">About the product</h3>
        <ul class="list-disc mt-4 lg:mt-8 list-outside pl-5">
          <li class="text-base lg:text-xl text-slate-700 mb-3"
              v-for="(bullet, i) in product.feature_bullets" :key="'bullet_'+i">
            {{ bullet }}
          </li>
        </ul>
        <img
                class="w-40 mt-12 mx-auto lg:mx-0"
                src="https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg"
                alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { createDeviceDetector } from "next-vue-device-detector";
export default {
  data() {
    return {
      amazonAd: null,
      product: null,
      slider: {
        img: null,
        currentIndex: 0
      }
    }
  },
  watch: {
    productTitle() {
      document.title = this.productTitle;
    }
  },
  computed: {
    productTitle() {
      if (this.amazonAd && this.amazonAd.product_title) {
        return this.amazonAd.product_title
      } else if (this.product) {
        return this.product.title;
      } else {
        return 'Attribution Insights';
      }
    },
    amazonLink() {
      if (this.amazonAd) {
        let url = this.amazonAd.influencer_campaign_full_url;

        if (this.$route.query.tag)
          url += '&tag=' + this.$route.query.tag;

        const device = createDeviceDetector()

        if (device.mobile) {
          url = url.replace("https://" + this.amazonAd.influencer_amazon_host, "com.amazon.mobile.shopping.web://amazon.com")
        }

        return url;
      }

      return '#';
    }
  },
  created() {
    this.loadProduct();
    this.loadAmazonAd();

  },
  methods: {
    changeImage(i, img) {
      this.slider.currentIndex = i;
      this.slider.img = img;
    },
    loadAmazonAd() {
      this.axios.get('/amazon-ad/' + this.$route.params.id)
        .then(res => {
          this.amazonAd = res.data.data;
        })
    },
    loadProduct() {
      this.axios.get('/product-details/' + this.$route.params.id)
        .then(res => {
          this.product = res.data.data;

          this.slider.img = this.product.images[0];
        })
    }
  }
}
</script>